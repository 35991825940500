/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/login.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { navigate } from "gatsby"
import Keys from '../../../constants/keys'
import Utils from "../../../utils"
import loadable from '@loadable/component'

const Web3Login = loadable(() => import("./Web3Login"))

// Onboard:
class Login extends React.Component {
  constructor () {
    super();
    this.state = {};
  }
  componentDidMount() {
    fetch("/api/account").then((result) => {
      if(result.ok) {
        navigate("/dashboard/");
      } else {
        this.setState({"authenticated": result.ok})
      }
    }).catch(() => {
      this.setState({"authenticated": false})
    });
  }
  render() {

    const handleChange = (e) => {
      let state = {};
      state[e.target.name] = e.target.value;
      this.setState(state)
    }
    const handleLoginError = (e) => {
      this.setState({error: e});
    }
    const handleSubmit = (e) => {
      e.preventDefault();
      window.grecaptcha.execute(Keys.recaptcha_site_key, {action: 'login'}).then((recaptchaToken) => {
        return fetch("/api/login", {
          method: "POST",
          body: JSON.stringify({
            recaptcha_token: recaptchaToken,
            email: this.state.email,
            password: this.state.password,
          }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Utils.getCookie('csrftoken'),
          }
        });
      }).then((response) => { return response.json() }).then((result) => {
          if(result.ok) {
            window.location = "/dashboard/";
          } else {
            this.setState({error: "Login failed"});
          }
      });
    }
    if(this.state.authenticated === undefined) {
      return null;
    } else if(this.state.authenticated) {
      return <AniLink fade to="/dashboard/">Go To Dashboard</AniLink>
    } else {
      return (
        <section className={styles.login}>
        <span className={styles.modalHeader}><Title title="rivet" subtitle="login" /></span>
        <div className={styles.center}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div>
              <input
              type="email"
              name="email"
              id="email"
              placeholder="email"
              onChange={handleChange}
              className={styles.formControl}
              />
              <input
              type="password"
              name="password"
              id="password"
              placeholder="password"
              onChange={handleChange}
              className={styles.formControl}
              />
            </div>
            <div className={styles.arrowButton}>
              <input
              type="submit"
              value="submit"
              className={styles.submit}
              action=""
              />
            </div>
            <div className="errorMessage">{this.state.error}</div>
          </form>
        <Web3Login onError={handleLoginError} ></Web3Login>
        <p className={styles.instructions}><AniLink fade to="/rescue/">Forgot your password?</AniLink></p>
        <p className={styles.instructions}>Don&apos;t have an account? <AniLink fade to="/buidl/">Sign up!</AniLink></p>
        </div>
        </section>
      )
    }
  }
}

export default Login
