/* eslint-disable */
import React, { Component } from "react"
import NullLayout from "../components/NullLayout"
import StyledHero from "../components/StyledHero"
import LoadRecaptcha from "../components/LoadRecaptcha"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Login from "../components/Home/Login/Login"
export default class login extends Component {
  render() {
    return (
      <NullLayout>
        <Helmet>
          <title>Rivet | Login</title>
        </Helmet>
        <LoadRecaptcha />
        <StyledHero img="none">
        <Login />
        </StyledHero>
      </NullLayout>
    )
  }
}

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
