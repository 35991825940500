/* eslint-disable */
import React from "react"
import NullNavbar from "./NullNavbar"
import Footer from "./Footer"
import "./layout.css"

const NullLayout = ({ children }) => {
  return (
    <main>
      <NullNavbar />
      {children}
      <Footer />
    </main>
  )
}

export default NullLayout
